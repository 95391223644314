export const m = {
  bqhd: 'MAX',
  hdxq: 'Project introduction',
  zhuye: 'Home page',
  sqtjcg: 'Authorization succeeds',
  sqtjsb: 'Authorization failure',
  yebz: 'Insufficient balance',
  nomore: 'No more ',
  tixian: 'Withdrawal',
  txjl:"Withdrawal record",
  tbgnzt: 'Withdrawal function suspended',
  zwkf: 'Tips: Not open!!',
  ktslbz: 'The amount of withdrawable coins is insufficient',
  asdds: 'Type or long press to paste the address',
  tbsl: 'Enter the withdrawal amount',
  sxf: 'Handling charge',
  qdylq: "Are you sure to pick it up?",
  lqlj: "Definitive collection",
  tjiaolog: 'Extracting...',
  tibsuc: 'Successful withdrawal',
  tiberr: 'Withdrawal failure',
  fzdz: 'Copy address',
  noader: 'The invitation address you bind does not meet the binding requirements!',
  loading: 'Loading...',
  zzqb: 'Support wallet',
  qbsqtt: 'Wallet authorizes your account',
  sqinfo: 'To use the features of your personal account, use Wallet authorization',
  sqbtn: 'Wallet authorization',
  sqload: 'Under authorization...',
  dltt: 'Log in to your account',
  dlinfo: 'To use the features of your personal account, click on the link Wallet',
  dlbtn: 'Linked wallet',
  xzlang: 'Select language',
  whqyqdz: 'The address of the inviter was not obtained',
  bangsuc: "Binding successful",
  banger: 'Binding failure',
  qxsq: 'Please authorize first!',
  bdyqrdz: 'Please bind the inviter address!',
  qsydzdl: 'Please use your HECO address to log in to the game',
  yqlink: 'Invite link',
  yqinfo: 'Share it with your partner via the invite link',
  yqr: 'Referrer',
  yqjl: 'Invitation record',
  fzcg: 'Successful replication',
  fzsb: 'Replication failure',
  reasuc: 'Refresh successfully',
  bdtt: 'Bind parent',
  bdinfo: 'Please enter your referrer\'s wallet address',
  bdbtn: 'Confirm binding',
  bdload: 'Bound...',
  yqtg: 'My community',
  djs: 'Countdown:',
  zfload: 'During the payment...',
  qdzf: 'Firm payment',
  wan: 'wan',
  cyje: 'Payment quantity',
  jrsy: 'Today\'s earnings',
  ljsy: 'Cumulative income',
  rgjl: 'My order',
  dj: 'Lv.',
  zfje: 'Amount paid',
  zzc: 'Asset balance',
  day: 'Day',
  shi:"-",
  fen:":",
  miao:"",
  zfsl: 'Payment quantity',
  fxrs: 'Share number',
  sqrs: 'Community size',
  grztzc: 'Personal leasing',
  ztztzc: "Direct subscription quantity",
  sqztzc: 'Community leasing',
  fxjl: 'Share the reward',
  sqjl: 'Community reward',
  zxtbed: 'Minimum withdrawal limit',
  syz: 'In gains',
  wdfxrs: 'My share number',
  wdsqrs: 'Community size',
  sbzl: "Universal NFT",
  fhsy: 'Dividend income',
  sqsy: 'Community income',
  qysy: "Income on equity",
  lqkc: 'Claim deduction',
  pttj: "Platform addition",
  ptkc: "Platform deduction",
  xzrged: 'Select purchase quantity',
  qrcy: 'Confirmation of purchase',
  zlsl: "Universal NFT",
  zfje: "Amount paid",
  zldj: "Purchase unit price",
  zljs: "Purchase settlement",
  zfqr: "Purchase confirmation",
  azq: "Installation period",
  yyz: "In reservation",
  yxz: "In operation",
  yjs: "Already ended",
  wddd: "My order",
  rgcg: 'Purchase success',
  rgsb: 'Purchase failure',
  lqsg: 'Receive successfully',
  lqsb: 'Claim failure',
  yhdj: "User level",
  wdtg: "My community",
  grlqze: "Total personal claim",
  sqlqze: "Community total",
  yeqbmx: "Asset Details",
  kssj: "Start Time",
  jzsj: "Cut-off time",
  bdsj: "Binding time",
  yhdz: "User address",
  qbye:"Balance",
  qxrg:"Cancellation of payment",
  azdjs:"Install countdown",
  yxdjs:"Run countdown",
  qrz:"Confirming",
  wdnft:"NFT records",
  rgsl:"Subscription number",
  qyjs:"Benefit Introduction",
  qy1:'1.Limited to 1000 copies available online',
  qy2:"2.Once sold, there is no refund.",
  qy3:'Rights and interests ① Enjoy 10% weighted dividend of all box office profits of movies participated in by the platform.',
  qy4:'Rights and interests ② Online can obtain 1000u schedule share, enjoy 2000u income.',
  nyrg:"You have subscribed to this NFT",
  fhqb:"Dividend Wallet",
  jlqb:"Aleo Wallet",
  fgqb:"MAX wallet",
  lqz:"Withdrawing...",
  wdtz:'Record',
  xmjd:"Project schedule",
  lx:"Type",
  ed:"Limit",
  zt:"State",
  jxz:"In progress",
  yjs:'Ended',
  ystz:"Lease computing",
  ysxq:"Product purchase",
  grtred:"Individual subscription",
  grztzc:"Personal computing power",
  zttred:"Direct subscription",
  ztztzc:"Extrapolating force values",
  jtztzc:"Extrapolated force value",
  sqtred:"Community subscription",
  sqztzc:"Community computing power",
  yped:"Film quota",
  tzed:"investment quota",
  yplj:"Movie Link",
  xqjs:"Movies",
  wytz:"I want to invest",
  tzqj:"Investment interval",
  dappqb:"Dapp Wallet",
  tzje:"investment",
  fgqbye:"Balance",
  zfje:"Payment amount",
  zfqb:"Payment wallet",
  cjjd:"Outgoing node",
  zfsb:"Payment failed",
  sqjy:"Apply for Termination",
  rgq:"Subscription period",
  yjy:"Terminated",
  shcg:'Termination success',
  shsb:"Termination failed",
  zxtzed:"Minimum investment limit",
  dqbj:"Current price",
  fhtx:"Withdrawal deduction",
  jltx:"Withdrawal deduction",
  jlfg:"Reward repurchase points",
  rgjl:'Subscription Award',
  zfxl:'Total circulation',
  zyc:'Tfilm resource pool',
  yxhl:'Destroyed quantity',
  wljjc:'Future fund pool',
  shz:"in review",
  txcg:"Successful",
  zwsj:"No data available",
  typen1:'USDT static output MAX',
  typen2:"The USDT dynamically generates MAX",
  typen3:'Static output of computing power ALEO',
  typen4:"The computing power dynamically generates ALEO",
  ljgm:"Buy now",
  jiage:"Price",
  slzl:"Computing power lease",
  danjia:'Unit price',
  zongsu:"Totality",
  goumaifs:"Number of copies purchased",
  goumaize:"Total purchase",
  zengsosl:"Complimentary computing power",
  yunxinzouqi:"Operating cycle",
  yiyunxints:"Days run",
  fen:"Copies",
  suanli:"Computing power",
  zhouqi:"Cycle",
  gmsl:"Purchase quantity",
  zfze:"Total payment",
  ndye:"Your balance is not enough to pay",
  zxed:"Minimum subscription quantity 1 copy",
  qyjf:"Equity points",
  xhsl:"Destruction quantity",
  wxtszwkf:"Tips: Your withdrawal function is not open!",
  qxztzed:"The current project is over!",
  shengyu:"Residual"
}